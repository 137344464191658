.have_decisionmodels:not(.djs-connection) .djs-visual > :nth-child(1) {
    fill: rgb(21,82,128) !important;
}

.empty_decisionmodels:not(.djs-connection) .djs-visual > :nth-child(1) {
    fill: rgb(169,181,192) !important;
}

.highlight:not(.djs-connection) .djs-visual > :nth-child(1) {
    fill: cadetblue !important; /* color elements as green */
}

.highlightResolved:not(.djs-connection) .djs-visual > :nth-child(1) {
    fill: lawngreen !important; /* color elements as green */
}

.highlightPending:not(.djs-connection) .djs-visual > :nth-child(1) {
    fill: yellow !important; /* color elements as green */
}

.highlightMultiTask:not(.djs-connection) .djs-visual > :nth-child(1) {
    fill: rgb(116, 158, 229) !important; /* color elements as green */
}

.highlight-overlay {
    background-color: cadetblue; /* color elements as green */
    opacity: 0.4;
    pointer-events: none; /* no pointer events, allows clicking through onto the element */
    border-radius: 10px;
}


.diagram-container {
    height:  300px;
}

.react-bpmn-diagram-container {
    width: 100%;
    height:  100%;
}


.overlay-view {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    border: 3px solid cadetblue;
    padding: 3px;
}

.overlay-view-resolved {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    border: 3px solid lawngreen;
    padding: 3px;
}



.overlay-view-sucess {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    border: 3px solid lawngreen;
    padding: 3px;
}


.avatar-div {
    display: flex;
    flex-grow: 1;
    vertical-align: middle;
}

.overlay-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 8px;
}

.overlay-sub-content {
    display: inline-flex;
    flex-grow: 2;
    flex-direction: column;
    vertical-align: middle;
}

.name-div {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    align-items: center;
}


.name-span {
    display:inline-flex;
    white-space: nowrap;
    margin-top: 8px;
    color: black;
    font-weight: 900;
    font-size: 1.25em;
    line-height: 1.75em;
}

.red-name-span {
    display:inline-flex;
    white-space: nowrap;
    margin-top: 8px;
    color:  black;
    font-weight: 900;
    font-size: 1.25em;
    line-height: 1.75em;
}

.status-span{
    display:inline-flex;
    white-space: nowrap;
    color: black;
}

.red-status-span{
    display:inline-flex;
    white-space: nowrap;
    color: black;
}




.status-div {
    display: inline-flex;
    flex-grow: 1;
    white-space: nowrap;
}

.sub-text {
    white-space: nowrap;
    font-size: 10px;
}

.instance-overlay {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #2ad5ce;
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}
