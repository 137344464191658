/*@import "styles/image-gallery.css";*/
@import 'print.css';
@import 'tailwind.css';
/*@import 'styles/tables.css';*/
/*@import 'styles/react-table.css';*/
/*@import 'normalize.css';*/
/*@tailwind variants;*/
* {
    outline: none !important;
}

.-top-5 {
    top: -5px
}

div:has(> div[style*="opacity: 0"]) {
    pointer-events: none;
}

html {
    /*font-size: 8px;*/
    /*font-family: SamsungOne, Roboto, Helvetica Neue, Arial, sans-serif;*/
    font-family: "NotoSans", "SamsungOne", "Helvetica Neue", "Arial", sans-serif;
    background-color: #262933;
}

.rstcustom__rowLabel {
    padding-right: 0;
}

.rstcustom__rowContents {
    min-width: 120px !important;
}

.MuiInputBase-root {
    font-size: 16px
}

img {
    max-width: 100%;
}

.css-1po6soi-Input {
    height: 29px !important;
}

button {
    border: 0;
    color: inherit;
    padding: inherit;
    background-color: transparent;
}

.MuiFormLabel-asterisk {
    color: red;
    font-weight: bold;
}

body {
    font-size: 14px;
    line-height: 1.4;
}

.drag {
    -webkit-app-region: drag;
}

.no-drag {
    -webkit-app-region: no-drag;
}

.rstcustom__lineBlock {
    width: 10px !important;
}

html, body, #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    /*zoom: 0.95;*/
}

h1, .h1 {
    font-size: 24px;
}

h2, .h2 {
    font-size: 20px;
}

h3, .h3 {
    font-size: 16px;
}

h4, .h4 {
    font-size: 15px;
}

h5, .h5 {
    font-size: 13px;
}

h6, .h6 {
    font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
    z-index: 99;
}

a[role=button] {
    text-decoration: none;
}

a:hover {
    color: inherit;
}

/*[class*="border-"] {*/
/*    border-color: rgba(0, 0, 0, 0.12);*/
/*}*/

.loading {
    position: relative;
    min-height: 20px;
}

.loading:before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("loading.gif");
    display: block;
    position: absolute;
    background-position: center center;
    -webkit-background-size: 60px 60px;
    background-size: 50px 50px;
    background-color: rgba(255, 255, 255, .7);
    z-index: 100;
    background-repeat: no-repeat;
}


.whiteboard {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}


.hideUploader .dropzoneInner {
    display: none
}

.colors {
    position: fixed;
    width: 100%;
}

.color {
    display: inline-block;
    /*min-height: 48px;*/
    /*min-width: 48px;*/
}

.color:before {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    width: 30px;
    height: 30px;
    content: "";
    display: block;
    border-radius: 50%;
}

.table-row-im {
    display: table-row !important;
}

.color.black:before {
    background-color: black;
}

.color.red:before {
    background-color: red;
}

.color.green:before {
    background-color: green;
}

.color.blue:before {
    background-color: blue;
}

.color.yellow:before {
    background-color: yellow;
}

i.glyphicon:after, .rc-tree-select-tree li span.rc-tree-select-tree-checkbox:after {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
}

i.glyphicon-plus::after {
    content: 'add';
    font-size: 24px;
}

.glyphicon-arrow-up::after {
    content: 'arrow_upward';
}

.glyphicon-arrow-down::after {
    content: 'arrow_downward';
}

.glyphicon-remove:after {
    content: 'close';
}

.rc-tree-select-tree li span.rc-tree-select-tree-checkbox:after {
    content: 'check_box_outline_blank';
}

.rc-tree-select-tree li span.rc-tree-select-tree-checkbox-checked:after {
    content: 'check_box';
}

.rc-tree-select-tree li span.rc-tree-select-tree-checkbox-indeterminate:after {
    content: 'indeterminate_check_box';
}

.field-array-of-string .row {
    margin: 10px 0;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.array-item {
    width: 100%;
    display: flex;
    margin: 10px 0;
}

.array-item .form-control {
    height: 32px;
    font-size: 16px;
}

.array-item .col-xs-9 {
    width: 55%;
}

.array-item .col-xs-3 {
    width: 45%;
}

.panel-heading {
    padding: 10px;
}

.panel.panel-danger.errors {
    margin: 15px;
}

.border-less-input:before {
    content: none
}

.nowrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.amap-sug-result {
    z-index: 99999;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.bg-REJECT {
    background-color: #e3342f
}

.bg-DELETE {
    background-color: #e3342f
}

.bg-DONE {
    background-color: #51d88a
}

.bg-OPEN-lighter {
    background-color: #ffffdc
}

.bg-DOING-lighter {
    background-color: #ffffff
}

.bg-DONE-lighter {
    background-color: #26ca6e
}

.bg-CONFIRMED-lighter {
    background-color: #d6bbfc
}

.bg-DONE-darker {
    background-color: #1a4731
}

.bg-CONFIRMED {
    background-color: #9561e2
}

.bg-brand-darkest {
    background-color: #191e38
}

.bg-brand-darker {
    background-color: #2f365f
}

.bg-brand-dark {
    background-color: #5661b3
}

.bg-brand {
    background-color: #6574cd
}

.bg-brand-light {
    background-color: #7886d7
}

.bg-brand-lighter {
    background-color: #b2b7ff
}

.bg-brand-lightest {
    background-color: #e6e8ff
}

.bg-customer-darkest {
    background-color: #21183c
}

.bg-customer-darker {
    background-color: #382b5f
}

.bg-customer-dark {
    background-color: #794acf
}

.bg-customer {
    background-color: #9561e2
}

.bg-customer-light {
    background-color: #a779e9
}

.bg-customer-lighter {
    background-color: #d6bbfc
}

.bg-customer-lightest {
    background-color: #f3ebff
}

.bg-project-darkest {
    background-color: #21183c
}

.bg-project-darker {
    background-color: #144D47
}

.bg-project-dark {
    background-color: #195f54
}

.bg-project {
    background-color: #318b81
}

.bg-project-light {
    background-color: #1f9b94
}

.bg-project-lighter {
    background-color: #25bfb8
}

.bg-project-lightest {
    background-color: #2ad5ce
}

.bg-task-darkest {
    background-color: #12283a
}

.bg-task-darker {
    background-color: #1c3d5a
}

.bg-task-dark {
    background-color: #2779bd
}

.bg-task {
    background-color: #3490dc
}

.bg-task-light {
    background-color: #6cb2eb
}

.bg-task-lighter {
    background-color: #bcdefa
}

.bg-task-lightest {
    background-color: #eff8ff
}

.text-REJECT {
    color: #e3342f
}

.text-DELETE {
    color: #e3342f
}

.text-DONE {
    color: #51d88a
}

.text-DONE-lighter {
    color: #a2f5bf
}

.text-DONE-darker {
    color: #1a4731
}

.text-CONFIRMED {
    color: #9561e2
}

.text-CONFIRMED-lighter {
    color: #d6bbfc
}

.text-brand-darkest {
    color: #191e38
}

.text-brand-darker {
    color: #2f365f
}

.text-brand-dark {
    color: #5661b3
}

.text-brand {
    color: #6574cd
}

.text-brand-light {
    color: #7886d7
}

.text-brand-lighter {
    color: #b2b7ff
}

.text-brand-lightest {
    color: #e6e8ff
}

.text-customer-darkest {
    color: #21183c
}

.text-customer-darker {
    color: #382b5f
}

.text-customer-dark {
    color: #794acf
}

.text-customer {
    color: #9561e2
}

.text-customer-light {
    color: #a779e9
}

.text-customer-lighter {
    color: #d6bbfc
}

.text-customer-lightest {
    color: #f3ebff
}

.text-project-darkest {
    color: #21183c
}

.text-project-darker {
    color: #144D47
}

.text-project-dark {
    color: #195f54
}

.text-project {
    color: #318b81
}

.text-project-light {
    color: #1f9b94
}

.text-project-lighter {
    color: #25bfb8
}

.text-project-lightest {
    color: #2ad5ce
}

.text-task-darkest {
    color: #12283a
}

.text-task-darker {
    color: #1c3d5a
}

.text-task-dark {
    color: #2779bd
}

.text-task {
    color: #3490dc
}

.text-task-light {
    color: #6cb2eb
}

.text-task-lighter {
    color: #bcdefa
}

.text-task-lightest {
    color: #eff8ff
}

.ellipsis {
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
    ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar:hover {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0.06);
    }

    ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb:active {
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
        border-radius: 20px;
    }
}

.file-drop {
    /* relatively position the container bc the contents are absolute */
    position: relative;
    /*height: 100%;*/
    /*width: 100%;*/
}

.file-drop > .file-drop-target {

}

.file-drop > .file-drop-target.file-drop-dragging-over-frame:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.5);
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
    /* turn stuff orange when we are dragging over the target */
    opacity: 0.8
}

#filters .css-1po6soi-Input {
    color: white !important;
    font-size: 14px;
}


@media screen and (min-width: 600px) {
    .dghider .dghid {
        opacity: 0;
        display: none;
        transition-duration: 200ms;
    }
}

.dghider:hover .dghid {
    opacity: inherit;
    display: flex;
}

@media screen and (min-width: 600px) {
    .hider .hid {
        opacity: 0;
        transition-duration: 200ms;
    }
}

.hider:hover .hid {
    opacity: inherit;
}

.fixed-bottom {
    position: fixed;
    bottom: 0;
}

.balance {
    /*noinspection CssInvalidPropertyValue*/
    text-wrap: balance;
}

.sanitize-html ol {
    list-style: decimal;
    padding-left: 20px;
}

.sanitize-html ul {
    list-style: circle;
    padding-left: 20px;
}


.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
    margin-right: 0px !important;
    min-width: 0px;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 0px !important;
}


._is6ww > p {
    overflow: visible;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.editor-input ol {
    list-style: decimal;
    padding-left: 20px;
}


.rstcustom__rowWrapper {
    padding-top: 3px;
    padding-bottom: 3px;
}

.rst__node {
    height: 44px !important;
}


.last-pb-0:last-child {
    padding-bottom: 0 !important;
}

.w-fit {
    width: fit-content;
}

.twitter-picker {
    box-shadow: none !important;
}

.webuploader-container {
    position: relative;
    line-height: 0;
}

.webuploader-element-invisible {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

.webuploader-pick {
    position: relative;
    display: inline-block;
    cursor: pointer;
    background: transparent !important;
    padding: 0 !important;
    color: inherit !important;
    text-align: center;
    border-radius: 0;
    overflow: hidden;
}

.webuploader-pick-hover {
    background: transparent !important;
}

.webuploader-pick-disable {
    opacity: 0.6;
    pointer-events: none;
}

.scroll-container {
    will-change: transform;
}
